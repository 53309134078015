import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 525.000000 475.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,475.000000) scale(0.100000,-0.100000)">
          <path d="M2665 4020 c-44 -18 -78 -67 -83 -119 -6 -61 10 -102 51 -137 18 -15
26 -28 20 -32 -19 -13 -33 -51 -33 -92 0 -31 6 -47 26 -65 15 -14 33 -25 40
-25 12 0 14 -58 16 -315 1 -173 2 -332 3 -352 0 -37 -1 -38 -37 -41 l-38 -3
-2 333 c-3 325 -3 333 -23 333 -19 0 -20 -5 -15 -135 3 -74 5 -223 5 -330 l0
-195 -42 -3 -43 -3 -1 198 c0 109 0 302 1 429 1 197 -1 232 -13 237 -8 3 -18
3 -21 0 -3 -4 -6 -199 -6 -435 l0 -428 -35 0 -35 0 0 114 c0 91 -3 115 -15
119 -23 10 -28 -10 -29 -122 l-1 -106 -35 0 -35 0 -3 143 c-3 142 -2 142 21
151 13 5 36 24 51 42 24 28 27 39 23 74 -5 38 -43 85 -76 96 -7 3 -12 50 -13
142 -2 124 -4 137 -20 137 -16 0 -18 -12 -18 -140 0 -134 -1 -140 -22 -151
-38 -19 -58 -51 -58 -95 0 -45 26 -88 60 -99 19 -6 20 -16 20 -151 l0 -144
-31 0 -31 0 3 93 c4 87 3 92 -17 95 -29 4 -33 -11 -22 -96 11 -85 8 -99 -24
-97 l-23 2 -2 393 c-2 362 -7 434 -27 401 -4 -6 -8 -187 -9 -401 l-2 -391
-467 1 c-459 0 -511 -3 -467 -31 8 -5 708 -8 1604 -6 1406 2 1590 4 1590 17 0
13 -60 15 -474 18 l-474 2 5 305 c5 292 4 305 -13 305 -18 0 -19 -14 -19 -305
l0 -305 -26 0 -25 0 2 133 2 132 -23 0 c-24 0 -24 0 -24 -135 l-1 -135 -25 0
-25 0 -3 145 -3 145 36 33 c30 29 35 40 35 79 0 36 -6 50 -30 75 -16 16 -30
31 -30 33 0 1 16 6 35 9 36 7 65 44 65 85 0 33 -48 81 -81 81 -28 0 -29 1 -29
50 0 43 -3 50 -20 50 -18 0 -20 -7 -20 -61 0 -33 -5 -70 -11 -82 -15 -26 -14
-76 0 -90 19 -19 12 -34 -20 -47 -39 -16 -69 -64 -69 -108 0 -25 9 -43 34 -68
19 -19 41 -34 49 -34 18 0 30 -103 25 -220 l-3 -75 -35 0 -35 0 -2 124 c-1 90
-5 126 -15 132 -26 16 -32 -12 -29 -136 l3 -125 -37 0 -36 0 2 411 c2 355 0
413 -13 420 -22 12 -58 -17 -58 -47 0 -13 7 -27 15 -30 13 -5 15 -57 15 -381
l0 -374 -42 3 -43 3 -3 193 c-2 177 -4 193 -20 190 -15 -3 -17 -21 -17 -193
l0 -190 -35 0 -35 0 -3 351 -2 352 25 6 c30 8 65 60 65 96 0 14 -9 39 -19 57
l-20 31 34 37 c93 98 29 257 -102 254 -21 0 -47 -4 -58 -9z m104 -45 c32 -16
55 -62 48 -97 -4 -21 -4 -21 -6 4 -2 49 -84 83 -130 54 -11 -7 -11 -10 0 -18
10 -7 7 -7 -8 -3 -19 5 -23 1 -28 -32 -10 -58 -12 -60 -20 -29 -13 48 5 96 45
117 42 23 62 24 99 4z m-1 -271 c16 -11 22 -25 22 -54 0 -44 -26 -70 -71 -70
-30 0 -69 40 -69 70 0 10 9 29 21 44 24 30 63 34 97 10z m462 -223 c0 -6 -11
-23 -24 -38 -22 -25 -25 -26 -31 -10 -5 11 -1 25 10 37 19 21 45 27 45 11z
m-916 -187 c24 -23 21 -48 -3 -34 -30 15 -52 12 -78 -12 l-23 -21 0 23 c0 51
68 80 104 44z m898 -1 c10 -9 18 -30 18 -49 0 -57 -69 -85 -110 -44 -60 60 29
150 92 93z"/>
          <path d="M2707 3679 c-21 -12 -22 -42 -3 -58 20 -16 56 3 56 29 0 25 -30 41
-53 29z"/>
          <path d="M3153 3273 c-18 -7 -16 -52 2 -59 18 -7 45 12 45 32 0 16 -30 33 -47
27z"/>
          <path d="M2415 3896 c-13 -19 -14 -29 -5 -45 14 -27 56 -28 70 -2 14 27 13 37
-6 55 -22 23 -41 20 -59 -8z m45 -27 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0
8 5 12 10 9 6 -3 10 -10 10 -16z"/>
          <path d="M2978 3909 c-22 -12 -23 -52 -4 -69 42 -34 94 22 56 60 -23 23 -28
24 -52 9z m34 -34 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6
-2 11 -8 11 -13z"/>
          <path d="M2237 3752 c-22 -25 -21 -38 5 -56 42 -30 87 24 48 56 -28 22 -33 22
-53 0z m43 -16 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z"/>
          <path d="M3154 3749 c-24 -27 -8 -54 32 -54 23 0 30 5 32 24 3 15 -3 30 -14
38 -24 17 -28 17 -50 -8z m36 -19 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6
5 10 10 10 6 0 10 -4 10 -10z"/>
          <path d="M3357 3664 c-4 -4 -7 -34 -7 -66 0 -52 2 -58 20 -58 19 0 21 5 18 62
-3 57 -14 79 -31 62z"/>
          <path d="M1965 3518 c-3 -7 -6 -134 -7 -283 l-3 -270 -115 3 c-118 3 -171 -5
-178 -26 -3 -10 36 -12 165 -10 l168 3 3 298 c2 262 0 297 -13 297 -9 0 -18
-6 -20 -12z"/>
          <path d="M3455 3518 c-3 -7 -4 -141 -3 -298 l3 -285 139 -3 c89 -2 143 1 150
8 21 21 -19 30 -136 30 l-118 0 -2 277 c-2 210 -6 277 -15 281 -7 2 -15 -2
-18 -10z"/>
          <path d="M1500 3490 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
          <path d="M1555 3491 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />
          <path d="M3865 3491 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />
          <path d="M3920 3490 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
          <path d="M1852 3479 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
          <path d="M3570 3480 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
          <path d="M2817 3448 c-8 -31 2 -68 19 -68 10 0 14 13 14 45 0 49 -23 64 -33
23z"/>
          <path d="M1700 3410 c-18 -11 -4 -40 20 -40 13 0 20 7 20 19 0 22 -21 33 -40
21z"/>
          <path d="M3710 3391 c0 -22 21 -33 40 -21 18 11 4 40 -20 40 -13 0 -20 -7 -20
-19z"/>
          <path d="M1605 3391 c-3 -5 -1 -12 5 -16 12 -7 30 2 30 16 0 12 -27 12 -35 0z" />
          <path d="M1802 3388 c6 -16 32 -22 64 -14 37 10 19 26 -27 26 -28 0 -39 -4
-37 -12z"/>
          <path d="M3563 3388 c2 -7 19 -13 37 -13 18 0 35 6 38 13 2 8 -9 12 -38 12
-29 0 -40 -4 -37 -12z"/>
          <path d="M3815 3390 c-3 -5 0 -13 8 -16 23 -9 152 0 152 11 0 14 -151 19 -160
5z"/>
          <path d="M1180 3346 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z" />
          <path d="M4245 3349 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />
          <path d="M1457 3304 c-20 -21 10 -24 183 -22 118 2 190 7 190 13 0 11 -362 20
-373 9z"/>
          <path d="M3620 3295 c0 -13 27 -15 180 -15 153 0 180 2 180 15 0 13 -27 15
-180 15 -153 0 -180 -2 -180 -15z"/>
          <path d="M1336 3195 c-34 -32 -35 -77 -1 -110 13 -14 36 -25 50 -25 38 0 75
42 75 85 0 29 -6 39 -32 55 -43 26 -60 25 -92 -5z m68 -21 c25 -9 21 -61 -5
-69 -30 -10 -53 16 -45 49 6 26 20 31 50 20z"/>
          <path d="M1777 3214 c-17 -18 -3 -24 54 -24 44 0 60 3 57 13 -5 13 -99 23
-111 11z"/>
          <path d="M3557 3214 c-15 -15 8 -24 64 -24 44 0 60 3 57 13 -5 13 -109 23
-121 11z"/>
          <path d="M4029 3209 c-26 -10 -49 -45 -49 -75 0 -22 55 -74 78 -74 11 0 31 9
46 21 20 16 26 29 26 58 0 57 -47 89 -101 70z m48 -37 c21 -13 20 -57 -2 -66
-35 -13 -63 21 -45 55 11 20 27 24 47 11z"/>
          <path d="M1598 3188 c-27 -22 -30 -53 -7 -81 11 -15 29 -27 39 -27 30 0 63 34
63 65 0 47 -57 72 -95 43z m60 -47 c-2 -9 -11 -17 -21 -19 -21 -4 -35 27 -17
38 19 12 42 0 38 -19z"/>
          <path d="M3770 3180 c-32 -32 -24 -75 17 -91 40 -15 83 15 83 58 0 49 -63 70
-100 33z m57 -26 c8 -21 -13 -42 -28 -27 -13 13 -5 43 11 43 6 0 13 -7 17 -16z"/>
          <path d="M1775 3090 c-13 -21 11 -31 61 -28 36 2 49 7 49 18 0 19 -99 27 -110
10z"/>
          <path d="M3555 3090 c-13 -21 11 -31 66 -28 40 2 54 7 54 18 0 19 -109 28
-120 10z"/>
          <path d="M1127 2964 c-4 -4 -7 -13 -7 -21 0 -10 44 -13 226 -13 209 0 225 1
222 18 -3 15 -23 17 -219 20 -118 1 -218 -1 -222 -4z"/>
          <path d="M3803 2963 c-7 -2 -13 -11 -13 -19 0 -12 41 -14 268 -12 229 3 267 5
267 18 0 13 -36 15 -255 17 -140 1 -261 -1 -267 -4z"/>
          <path d="M1130 2690 c0 -19 7 -20 115 -20 108 0 115 1 115 20 0 19 -7 20 -115
20 -108 0 -115 -1 -115 -20z"/>
          <path d="M1400 2690 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
          <path d="M1470 2690 c0 -20 7 -20 1280 -20 1273 0 1280 0 1280 20 0 20 -7 20
-1280 20 -1273 0 -1280 0 -1280 -20z"/>
          <path d="M4070 2690 c0 -20 5 -20 128 -18 105 3 127 6 127 18 0 12 -22 15
-127 18 -123 2 -128 2 -128 -18z"/>
          <path d="M2050 2596 c0 -10 86 -232 149 -384 39 -95 71 -175 71 -178 0 -3 16
-43 36 -90 l36 -84 79 0 c77 0 79 1 94 30 19 36 77 178 116 280 27 73 101 254
119 295 10 21 50 128 50 133 0 1 -42 2 -94 2 l-93 0 -82 -215 c-44 -119 -81
-220 -81 -225 0 -5 -5 -21 -11 -37 -8 -22 -12 -25 -19 -13 -11 21 -144 374
-165 438 l-17 52 -94 0 c-52 0 -94 -2 -94 -4z"/>
          <path d="M2331 2563 c31 -81 82 -193 89 -193 5 0 6 9 3 20 -3 11 -1 20 5 20 6
0 10 8 10 18 0 9 1 24 3 32 2 8 -1 14 -8 15 -6 0 -15 3 -19 7 -3 4 3 6 15 3
14 -2 21 1 21 11 0 9 5 12 13 8 7 -4 8 -3 4 2 -5 5 -16 9 -24 9 -9 0 -18 7
-20 15 -4 13 -3 13 7 0 9 -11 13 -12 17 -2 2 6 10 9 15 6 7 -4 8 -2 4 5 -4 6
-11 9 -16 6 -4 -3 -13 2 -20 10 -11 13 -10 14 6 9 18 -6 17 -5 -1 9 -12 10
-39 16 -67 17 -47 0 -47 0 -37 -27z"/>
          <path d="M2880 2225 l0 -365 90 0 90 0 0 365 0 365 -90 0 -90 0 0 -365z m103
220 c7 -17 -8 -29 -28 -21 -17 6 -9 36 10 36 7 0 15 -7 18 -15z m15 -148 c2
-9 -4 -20 -12 -23 -21 -8 -46 13 -39 32 8 20 47 13 51 -9z"/>
          <path d="M1214 2569 c-3 -6 -2 -15 4 -21 13 -13 32 -2 32 18 0 16 -26 19 -36
3z"/>
          <path d="M3213 2573 l-43 -4 0 -366 0 -365 168 4 c180 5 209 12 272 65 18 15
39 42 46 60 18 42 18 117 -1 152 -14 29 -63 75 -87 84 -9 3 -5 13 13 33 59 64
73 131 44 202 -20 47 -49 77 -104 110 -40 23 -55 26 -155 27 -61 1 -130 0
-153 -2z m267 -85 c50 -26 77 -59 86 -105 9 -48 -16 -42 -41 9 -34 71 -56 80
-199 86 -97 4 -126 8 -126 18 0 11 25 14 118 14 102 0 123 -3 162 -22z m-40
-108 c24 -24 26 -72 5 -90 -9 -7 -44 -14 -78 -14 l-62 -1 -3 63 -3 62 61 0
c47 0 64 -4 80 -20z m41 -270 c13 -7 19 -21 19 -45 0 -46 -20 -55 -119 -55
l-81 0 0 55 0 55 81 0 c44 0 89 -5 100 -10z"/>
          <path d="M1497 2563 c-4 -3 -7 -114 -7 -246 0 -205 -2 -243 -17 -267 -33 -57
-95 -65 -146 -20 -19 17 -38 30 -41 30 -11 0 -146 -91 -146 -99 0 -4 19 -27
42 -50 62 -63 127 -91 214 -91 116 0 198 53 248 160 19 41 21 66 24 318 l4
272 -84 0 c-47 0 -88 -3 -91 -7z"/>
          <path d="M1780 2200 l0 -370 85 0 85 0 0 370 0 370 -85 0 -85 0 0 -370z m100
225 c13 -16 6 -35 -15 -35 -18 0 -28 17 -21 35 7 19 20 19 36 0z m17 -141 c3
-8 -2 -23 -11 -32 -15 -15 -17 -15 -32 0 -21 21 -11 48 16 48 11 0 23 -7 27
-16z"/>
          <path d="M3770 2205 l0 -365 240 0 240 0 0 80 0 80 -155 0 -155 0 0 60 0 59
138 3 137 3 3 74 c2 53 -1 77 -10 82 -7 5 -69 7 -138 6 l-125 -2 -3 58 -3 57
156 0 155 0 0 85 0 85 -240 0 -240 0 0 -365z"/>
          <path d="M870 2489 c-20 -37 28 -67 58 -37 17 17 15 45 -4 52 -28 11 -43 7
-54 -15z m42 -14 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6
-2 11 -8 11 -13z"/>
          <path d="M4519 2494 c-17 -21 0 -49 31 -49 31 0 48 28 31 49 -8 9 -21 16 -31
16 -10 0 -23 -7 -31 -16z m41 -19 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8
5 15 10 15 6 0 10 -7 10 -15z"/>
          <path d="M1130 2446 c0 -17 37 -23 160 -25 95 -1 129 7 116 28 -5 7 -54 11
-142 11 -108 0 -134 -3 -134 -14z"/>
          <path d="M1147 2313 c-19 -18 1 -23 93 -23 82 0 100 3 100 15 0 12 -17 15 -93
15 -52 0 -97 -3 -100 -7z"/>
          <path d="M1387 2313 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
          <path d="M854 2286 c-60 -26 -70 -124 -17 -166 38 -30 106 -27 138 5 46 45 29
145 -28 165 -38 13 -55 12 -93 -4z m84 -38 c23 -23 14 -38 -15 -27 -24 9 -30
7 -45 -11 -10 -12 -15 -26 -12 -31 3 -5 1 -9 -4 -9 -10 0 -22 23 -22 41 0 5 9
18 20 29 22 22 60 26 78 8z"/>
          <path d="M4501 2288 c-14 -7 -34 -28 -44 -46 -17 -29 -18 -39 -8 -72 15 -50
44 -70 102 -70 40 0 51 4 73 31 34 40 35 97 3 134 -26 31 -89 42 -126 23z m94
-48 c19 -21 16 -24 -23 -20 -13 1 -34 -6 -47 -14 -31 -20 -35 -20 -35 1 0 48
71 70 105 33z"/>
          <path d="M1045 2190 c-15 -24 11 -30 126 -30 109 0 120 2 117 18 -3 15 -18 17
-120 20 -72 2 -119 -1 -123 -8z"/>
          <path d="M1360 2180 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
-13 2 -18 -3 -18 -18z"/>
          <path d="M876 1944 c-9 -8 -16 -19 -16 -23 0 -16 29 -32 52 -29 29 4 36 37 12
55 -23 17 -29 16 -48 -3z m44 -24 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6
7 10 15 10 8 0 15 -4 15 -10z"/>
          <path d="M4516 1935 c-9 -24 4 -45 29 -45 12 0 28 7 35 15 16 20 -3 45 -35 45
-13 0 -25 -7 -29 -15z m44 -15 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z"/>
          <path d="M2020 1700 l0 -60 34 0 c37 0 61 17 72 52 9 29 -35 68 -77 68 -29 0
-29 0 -29 -60z m64 34 c30 -12 13 -74 -20 -74 -6 0 -14 16 -16 35 -5 46 1 52
36 39z"/>
          <path d="M2200 1699 c0 -81 24 -77 28 4 2 46 0 57 -13 57 -12 0 -15 -13 -15
-61z"/>
          <path d="M2310 1740 c-25 -25 -25 -41 1 -74 17 -22 28 -27 58 -24 33 3 36 6
39 36 3 29 1 32 -22 32 -14 0 -26 -5 -26 -11 0 -6 7 -9 15 -5 9 3 15 0 15 -10
0 -21 -24 -28 -50 -14 -15 8 -20 19 -18 38 3 23 8 27 41 30 20 2 35 7 32 13
-11 16 -64 10 -85 -11z"/>
          <path d="M2490 1700 c0 -33 4 -60 10 -60 6 0 10 27 10 60 0 33 -4 60 -10 60
-6 0 -10 -27 -10 -60z"/>
          <path d="M2580 1750 c0 -5 9 -10 20 -10 17 0 20 -7 20 -44 0 -25 5 -48 10 -51
6 -4 10 13 10 44 0 48 2 51 26 51 14 0 23 4 19 10 -3 6 -28 10 -56 10 -27 0
-49 -4 -49 -10z"/>
          <path d="M2762 1715 c-25 -51 -30 -87 -10 -67 16 16 65 15 71 0 2 -7 8 -10 12
-6 8 8 -33 118 -44 118 -4 0 -17 -20 -29 -45z m34 -32 c-11 -11 -19 6 -11 24
8 17 8 17 12 0 3 -10 2 -21 -1 -24z"/>
          <path d="M2910 1700 l0 -60 35 0 c39 0 48 17 13 22 -20 3 -23 10 -26 51 -4 68
-22 58 -22 -13z"/>
          <path d="M3131 1746 c-17 -20 -7 -46 19 -53 11 -3 20 -12 20 -19 0 -10 -7 -13
-24 -8 -29 7 -44 -12 -16 -21 53 -17 87 27 46 60 -31 25 -34 39 -5 30 15 -5
20 -3 17 6 -7 20 -43 23 -57 5z"/>
          <path d="M3260 1717 c0 -30 6 -49 18 -60 25 -23 59 -21 72 2 13 25 13 101 0
101 -5 0 -10 -20 -10 -44 0 -29 -5 -46 -15 -50 -22 -8 -45 22 -45 60 0 19 -4
34 -10 34 -5 0 -10 -20 -10 -43z"/>
          <path d="M3430 1700 l0 -60 33 0 c47 0 56 10 53 58 -2 47 -16 62 -58 62 -27 0
-28 -1 -28 -60z m60 24 c0 -9 -7 -14 -17 -12 -25 5 -28 28 -4 28 12 0 21 -6
21 -16z m5 -43 c3 -5 1 -13 -5 -16 -15 -9 -43 3 -35 15 8 13 32 13 40 1z"/>
          <path d="M1142 1717 c-14 -16 -6 -27 20 -27 13 0 18 6 16 17 -4 22 -22 27 -36
10z"/>
          <path d="M1313 1714 c-7 -19 2 -28 22 -21 19 8 19 23 0 31 -9 3 -18 -1 -22
-10z"/>
          <path d="M1633 1723 c-7 -3 -13 -11 -13 -19 0 -11 26 -14 141 -14 128 0 140 2
137 18 -3 15 -18 17 -128 18 -69 0 -131 -1 -137 -3z"/>
          <path d="M3671 1721 c-29 -19 2 -31 85 -31 68 0 84 3 84 15 0 11 -11 15 -42
15 -24 0 -59 3 -78 5 -19 3 -41 2 -49 -4z"/>
          <path d="M3948 1723 c-16 -2 -28 -11 -28 -19 0 -11 15 -14 65 -14 49 0 65 3
65 14 0 24 -31 30 -102 19z"/>
          <path d="M4266 1715 c-3 -9 1 -18 10 -22 19 -7 39 11 30 26 -10 16 -33 13 -40
-4z"/>
          <path d="M1220 1705 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
          <path d="M1420 1705 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
          <path d="M1532 1708 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
          <path d="M4100 1705 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
          <path d="M4190 1705 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
          <path d="M1141 1591 c-7 -5 -10 -14 -7 -20 5 -8 439 -11 1587 -11 1343 0 1579
2 1579 14 0 8 -7 17 -15 20 -25 9 -3129 6 -3144 -3z"/>
          <path d="M4570 1455 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
          <path d="M1127 1453 c-4 -3 -7 -10 -7 -15 0 -4 200 -8 444 -8 244 0 458 -3
475 -6 l31 -6 2 -422 c2 -272 7 -421 13 -421 6 0 11 149 13 421 2 411 3 422
22 427 11 3 22 2 25 -2 9 -16 13 -215 5 -232 -7 -12 -5 -21 5 -29 33 -27 39
-7 39 131 l1 134 25 0 c21 0 26 -6 30 -34 7 -48 30 -53 30 -7 0 30 4 37 25 42
54 14 56 8 54 -158 -2 -150 -2 -153 19 -156 13 -2 22 2 22 10 1 7 1 80 0 161
l-1 148 33 -3 33 -3 3 -97 c2 -90 4 -98 22 -98 19 0 20 7 20 98 l0 97 37 -1
38 -1 5 -284 c4 -244 7 -284 20 -284 13 0 16 41 20 285 l5 285 35 0 35 0 3
-358 2 -357 -22 -9 c-34 -13 -58 -44 -65 -85 -4 -28 -2 -36 10 -36 9 0 18 13
22 31 5 21 17 35 39 46 30 14 36 14 64 -1 25 -13 32 -23 32 -46 0 -34 25 -42
33 -11 7 30 -19 78 -52 96 l-31 16 2 357 3 357 31 3 c41 4 42 -2 43 -227 0
-97 0 -180 1 -184 0 -5 6 -7 13 -5 9 4 13 58 17 209 l5 204 40 -1 40 -1 5
-424 c4 -368 7 -424 20 -424 13 0 15 54 18 419 2 445 1 436 50 436 22 0 22 -2
22 -115 0 -108 1 -115 20 -115 19 0 20 7 20 110 l0 110 30 0 c17 0 33 1 38 3
4 1 9 -22 12 -52 7 -75 30 -81 30 -8 0 50 2 55 25 59 13 3 27 3 30 0 3 -3 5
-59 5 -124 0 -108 2 -119 18 -116 15 3 17 18 20 120 2 104 5 116 22 121 10 3
21 2 24 -1 3 -3 6 -158 6 -344 0 -315 1 -338 18 -338 16 0 17 23 17 341 l0
340 430 0 c455 0 515 3 515 25 0 12 -242 14 -1598 14 -879 0 -1602 -3 -1605
-7z"/>
          <path d="M3578 1343 l-128 -4 0 -330 c0 -309 1 -330 18 -327 16 3 17 28 22
313 l5 310 128 3 c99 2 127 6 127 16 0 13 -24 28 -38 24 -4 -1 -64 -4 -134 -5z"/>
          <path d="M1120 1325 c0 -13 53 -15 420 -15 l420 0 0 -316 c0 -295 1 -315 18
-312 16 3 17 28 20 331 l2 327 -440 0 c-384 0 -440 -2 -440 -15z"/>
          <path d="M3840 1325 c0 -13 34 -15 245 -15 211 0 245 2 245 15 0 13 -34 15
-245 15 -211 0 -245 -2 -245 -15z"/>
          <path d="M2233 1294 c-3 -9 -1 -25 5 -35 7 -10 12 -52 12 -93 0 -66 -2 -75
-20 -81 -75 -24 -79 -155 -6 -195 25 -13 26 -18 26 -92 0 -70 2 -79 18 -76 14
3 17 16 20 80 2 66 6 78 22 83 33 11 70 64 70 101 0 48 -14 70 -61 100 l-41
25 2 87 c2 48 2 93 1 100 -2 18 -41 15 -48 -4z m75 -250 c12 -8 22 -22 22 -30
0 -17 -2 -17 -35 -2 -22 10 -29 8 -50 -12 -14 -13 -28 -21 -31 -17 -12 12 6
54 28 65 32 15 40 14 66 -4z m8 -92 c-8 -9 -18 -13 -22 -10 -3 4 3 15 13 25
10 10 20 15 22 10 2 -5 -4 -16 -13 -25z m-56 -2 c23 -18 24 -20 5 -20 -21 0
-45 17 -45 32 0 13 13 9 40 -12z"/>
          <path d="M1332 1187 c-44 -47 -11 -127 51 -127 40 0 52 8 71 44 39 75 -64 145
-122 83z m86 -55 c-2 -21 -9 -28 -30 -30 -23 -3 -29 2 -34 24 -5 20 -2 31 11
40 27 20 57 0 53 -34z"/>
          <path d="M1770 1195 c0 -13 11 -15 63 -13 34 2 62 8 62 13 0 6 -28 11 -62 13
-52 2 -63 0 -63 -13z"/>
          <path d="M3160 1155 c0 -53 -1 -55 -34 -69 -37 -15 -66 -59 -66 -98 0 -35 37
-88 70 -99 l29 -11 3 -121 c3 -101 6 -122 18 -122 12 0 15 21 18 122 2 116 4
122 27 137 64 42 69 123 10 175 -32 28 -35 35 -35 86 0 48 -2 55 -20 55 -18 0
-20 -7 -20 -55z m58 -122 c6 -9 12 -27 12 -39 l0 -23 -25 24 c-30 31 -49 32
-70 3 -14 -21 -14 -24 2 -36 10 -7 30 -12 45 -9 22 3 25 1 17 -10 -30 -36
-107 14 -93 61 15 53 80 70 112 29z"/>
          <path d="M3557 1204 c-15 -15 8 -24 64 -24 46 0 59 3 59 15 0 12 -13 15 -58
15 -32 0 -62 -3 -65 -6z"/>
          <path d="M4005 1185 c-30 -30 -32 -64 -4 -99 42 -54 129 -19 129 52 0 66 -78
95 -125 47z m85 -51 c0 -28 -25 -42 -49 -27 -22 14 -25 38 -6 57 22 22 55 4
55 -30z"/>
          <path d="M1602 1193 c-25 -10 -34 -53 -17 -79 20 -31 79 -34 95 -5 31 60 -15
110 -78 84z m52 -40 c4 -9 1 -20 -6 -24 -16 -10 -43 15 -34 30 11 16 33 13 40
-6z"/>
          <path d="M3767 1182 c-21 -23 -21 -41 -1 -70 29 -42 104 -18 104 35 0 47 -71
71 -103 35z m66 -24 c6 -19 -3 -38 -18 -38 -18 0 -28 17 -21 35 7 18 33 20 39
3z"/>
          <path d="M2470 919 c0 -243 1 -260 18 -257 16 3 17 25 20 261 2 252 2 257 -18
257 -20 0 -20 -6 -20 -261z"/>
          <path d="M1775 1090 c-12 -20 7 -30 55 -30 50 0 74 15 49 31 -19 12 -96 11
-104 -1z"/>
          <path d="M3561 1087 c-16 -21 -3 -27 55 -27 53 0 78 15 53 31 -22 14 -95 11
-108 -4z"/>
          <path d="M1460 1000 c-31 -21 15 -30 150 -30 133 0 169 7 156 29 -9 14 -285
15 -306 1z"/>
          <path d="M3675 1000 c-15 -24 12 -30 148 -30 140 0 176 7 163 29 -9 14 -302
15 -311 1z"/>
          <path d="M1185 969 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />
          <path d="M1493 913 c-22 -8 -14 -33 10 -33 30 0 40 10 26 27 -12 14 -16 15
-36 6z"/>
          <path d="M1650 905 c-11 -13 -11 -19 3 -32 21 -21 49 -9 45 19 -4 28 -30 35
-48 13z"/>
          <path d="M3764 905 c-7 -18 2 -45 15 -45 5 0 17 7 26 16 12 12 13 19 4 30 -15
18 -38 18 -45 -1z"/>
          <path d="M1750 895 c0 -10 11 -15 35 -15 24 0 35 5 35 15 0 10 -11 15 -35 15
-24 0 -35 -5 -35 -15z"/>
          <path d="M3610 895 c0 -11 12 -15 50 -15 38 0 50 4 50 15 0 11 -12 15 -50 15
-38 0 -50 -4 -50 -15z"/>
          <path d="M2820 805 c0 -24 5 -35 15 -35 10 0 15 11 15 35 0 24 -5 35 -15 35
-10 0 -15 -11 -15 -35z"/>
          <path d="M1840 820 c-11 -7 -12 -12 -3 -21 14 -14 46 -4 41 13 -6 16 -20 19
-38 8z"/>
          <path d="M3576 823 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
          <path d="M3870 796 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
          <path d="M1490 790 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
          <path d="M1550 790 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
          <path d="M3925 790 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
          <path d="M3357 714 c-13 -13 -7 -24 14 -24 11 0 19 5 17 12 -5 14 -22 21 -31
12z"/>
          <path d="M2257 664 c-13 -13 -7 -24 14 -24 11 0 19 5 17 12 -5 14 -22 21 -31
12z"/>
          <path d="M3350 645 c0 -20 4 -26 18 -23 9 2 17 12 17 23 0 11 -8 21 -17 23
-14 3 -18 -3 -18 -23z"/>
          <path d="M2460 621 c-14 -28 13 -55 41 -41 24 14 24 36 0 50 -26 14 -29 13
-41 -9z"/>
          <path d="M2694 615 c-6 -15 12 -45 27 -45 16 0 38 31 33 45 -8 20 -53 19 -60
0z"/>
          <path d="M2228 555 c-8 -19 -8 -28 2 -40 32 -38 94 1 70 45 -16 29 -58 26 -72
-5z m46 -26 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10
3 -15z"/>
          <path d="M3152 568 c-29 -29 -3 -72 40 -66 19 2 23 9 23 38 0 30 -4 35 -25 38
-14 2 -31 -3 -38 -10z m38 -33 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5
15 10 15 6 0 10 -7 10 -15z"/>
          <path d="M2678 523 c-53 -15 -91 -58 -102 -116 -10 -53 8 -99 56 -142 39 -36
43 -37 100 -33 52 4 63 9 96 41 32 33 36 43 40 99 4 60 2 65 -32 104 -45 52
-92 67 -158 47z m102 -50 c77 -45 63 -169 -22 -195 -81 -24 -142 23 -140 109
0 40 5 52 31 75 37 33 88 37 131 11z"/>
          <path d="M2694 425 c-31 -31 -38 -32 -23 -2 l11 22 -22 -22 c-18 -17 -21 -28
-16 -57 19 -98 156 -84 156 17 0 63 -60 87 -106 42z"/>
          <path d="M2410 431 c-22 -42 27 -86 61 -55 22 20 25 44 7 62 -18 18 -56 14
-68 -7z m50 -21 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z"/>
          <path d="M2972 438 c-30 -30 0 -75 42 -64 29 7 40 31 26 57 -12 21 -50 25 -68
7z m43 -28 c0 -7 -6 -15 -12 -17 -8 -3 -13 4 -13 17 0 13 5 20 13 18 6 -3 12
-11 12 -18z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
